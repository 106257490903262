import React, { useEffect, useState } from 'react';
import { useLocation, useOutletContext } from 'react-router-dom';
import { Box, CircularProgress, Grid, Tabs, Tab, useMediaQuery, useTheme } from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import SalesChart from './SalesChart';
import HarvestsChart from './HarvestsChart';
import MetricsCard from './MetricsCard';
import CostsBarChart from './CostsBarChart';
import { useFarmData } from '../context/FarmDataContext';

const FarmDetailsPage = () => {
    const location = useLocation();
    const zones = location.state?.zones;
    const { idToken, farm_id } = useOutletContext();
    const { farmData, fetchFarmData, loading } = useFarmData();
    const [salesView, setSalesView] = useState("zone_id");
    const [harvestView, setHarvestView] = useState("all");
    const [tabIndex, setTabIndex] = useState(0);

    console.log(zones);

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    // Fetch data using the context
    useEffect(() => {
        if (idToken && !farmData) {
            fetchFarmData(farm_id, idToken); // Fetch data via context
        }
    }, [farm_id, idToken, farmData, fetchFarmData]);

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <CircularProgress />
            </Box>
        );
    }
    const generateStackedBarData = () => {
        const data = {};

        // List of event types
        const eventTypes = ['fertilizerEvents', 'fungicideEvents', 'insecticideEvents', 'herbicideEvents'];

        // Aggregating event costs by year
        eventTypes.forEach(eventType => {
            farmData[eventType].forEach(event => {

                const year = new Date(event.date).getFullYear();

                if (!data[year]) {
                    data[year] = { year, totalSales: 0, fertilizerCost: 0, fungicideCost: 0, insecticideCost: 0, herbicideCost: 0 };
                }

                const eventCost = event.event_cost || 0;

                if (eventType === 'fertilizerEvents') {
                    data[year].fertilizerCost += eventCost;
                } else if (eventType === 'fungicideEvents') {
                    data[year].fungicideCost += eventCost;
                } else if (eventType === 'insecticideEvents') {
                    data[year].insecticideCost += eventCost;
                } else if (eventType === 'herbicideEvents') {
                    data[year].herbicideCost += eventCost;
                }
            });
        });

        return Object.values(data).sort((a, b) => a.year - b.year); // Convert to array and sort by year
    };

    const calculateTotalCostsAndChange = () => {
        const currentYear = new Date().getFullYear();
        const previousYear = currentYear - 1;

        const calculateYearlyCost = (events) => {
            return events
                .filter(event => new Date(event.date).getFullYear() === currentYear)
                .reduce((total, event) => total + (event.event_cost || 0), 0);
        };

        const calculatePreviousYearCost = (events) => {
            return events
                .filter(event => new Date(event.date).getFullYear() === previousYear)
                .reduce((total, event) => total + (event.event_cost || 0), 0);
        };

        const currentYearCost = ['fertilizerEvents', 'fungicideEvents', 'insecticideEvents', 'herbicideEvents'].reduce(
            (total, eventType) => total + calculateYearlyCost(farmData[eventType]),
            0
        );

        const lastYearCost = ['fertilizerEvents', 'fungicideEvents', 'insecticideEvents', 'herbicideEvents'].reduce(
            (total, eventType) => total + calculatePreviousYearCost(farmData[eventType]),
            0
        );

        const change = lastYearCost !== 0 ? ((currentYearCost - lastYearCost) / lastYearCost) * 100 : 0;

        return {
            totalCost: currentYearCost.toFixed(2),
            change: change.toFixed(1),
            isIncrease: change >= 0,
            previousYear
        };
    };

    const calculateTotalAndChange = (data, field) => {
        const currentYear = new Date().getFullYear();
        const previousYear = currentYear - 1;
        const currentYearTotal = data
            .filter(item => new Date(item.date).getFullYear() === currentYear)
            .reduce((total, item) => total + (item[field] || 0), 0);

        const lastYearTotal = data
            .filter(item => new Date(item.date).getFullYear() === previousYear)
            .reduce((total, item) => total + (item[field] || 0), 0);

        const change = lastYearTotal !== 0 ? ((currentYearTotal - lastYearTotal) / lastYearTotal) * 100 : 0;

        return {
            total: currentYearTotal.toFixed(2),
            change: change.toFixed(1),
            isIncrease: change >= 0,
            previousYear
        };
    };

    const calculatePostHarvestLossAndChange = (harvests, sales) => {
        const currentYear = new Date().getFullYear();
        const previousYear = currentYear - 1;

        const currentYearHarvest = harvests
            .filter(h => new Date(h.date).getFullYear() === currentYear)
            .reduce((total, h) => total + (h.harvest_amt || 0), 0);

        const lastYearHarvest = harvests
            .filter(h => new Date(h.date).getFullYear() === previousYear)
            .reduce((total, h) => total + (h.harvest_amt || 0), 0);

        const currentYearSalesHarvestAmt = sales
            .filter(s => new Date(s.date).getFullYear() === currentYear)
            .reduce((total, s) => total + (s.harvest_amt || 0), 0);

        const lastYearSalesHarvestAmt = sales
            .filter(s => new Date(s.date).getFullYear() === previousYear)
            .reduce((total, s) => total + (s.harvest_amt || 0), 0);

        const currentYearLoss = ((currentYearHarvest - currentYearSalesHarvestAmt) / currentYearHarvest) * 100;
        const lastYearLoss = ((lastYearHarvest - lastYearSalesHarvestAmt) / lastYearHarvest) * 100;

        const change = lastYearLoss !== 0 ? ((currentYearLoss - lastYearLoss) / Math.abs(lastYearLoss)) * 100 : 0;

        return {
            totalLoss: currentYearLoss.toFixed(1),
            change: change.toFixed(1),
            isIncrease: change >= 0,
            previousYear
        };
    };

    if (loading || !farmData) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <CircularProgress />
            </Box>
        );
    }

    const totalCosts = calculateTotalCostsAndChange();
    const totalSales = calculateTotalAndChange(farmData.sales, "sale_amt");
    const totalHarvests = calculateTotalAndChange(farmData.harvests, "harvest_amt");
    const postHarvestLoss = calculatePostHarvestLossAndChange(farmData.harvests, farmData.sales);

    const generateSalesData = () => {
        const salesData = {};

        farmData.sales.forEach(sale => {
            const date = new Date(sale.date);
            const year = date.getFullYear();

            const harvest = farmData.harvests.find(harvest => harvest.id === sale.harvest_id);

            const plantType = harvest?.plant_type_cl || harvest?.plant_type || "Unknown";
            const zoneId = sale.zone_id || "Unknown";
            const zone = zones.find(zone => zone.id === zoneId);
            const zoneName = zone ? zone.name : "Unknown Zone";

            // Group by either zone_id or plant_type
            const groupKey = salesView === "zone_id" ? zoneName : (salesView === "plant_type" ? plantType : "All");

            if (!salesData[year]) {
                salesData[year] = {};
            }

            if (!salesData[year][groupKey]) {
                salesData[year][groupKey] = 0;
            }

            // Sum up the sales amount for the group
            salesData[year][groupKey] += sale.sale_amt;
        });

        // Convert the salesData object into an array suitable for Recharts PieChart
        return Object.keys(salesData).flatMap(year =>
            Object.keys(salesData[year]).map((group, index) => ({
                id: `${year}-${group}-${index}`,
                name: group,
                value: salesData[year][group],
                year: parseInt(year) // Include the year in each object
            }))
        );
    };

    const generateHarvestData = () => {
        const harvestData = {};

        // Extract all unique zone_ids and plant_types dynamically
        const allZoneIds = [...new Set(farmData.harvests.map(harvest => harvest.zone_id))];
        const allPlantTypes = [...new Set(farmData.harvests.map(harvest => harvest.plant_type_cl || harvest.plant_type))];

        farmData.harvests.forEach(harvest => {
            const date = new Date(harvest.date);
            const monthYear = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;

            const plantType = harvest.plant_type_cl || harvest.plant_type || "Unknown";
            const groupKey = harvestView === "zone_id" ? harvest.zone_id : (harvestView === "plant_type" ? plantType : "All");

            if (!harvestData[monthYear]) {
                harvestData[monthYear] = { monthYear };
            }

            if (!harvestData[monthYear][groupKey]) {
                harvestData[monthYear][groupKey] = 0;
            }

            harvestData[monthYear][groupKey] += harvest.harvest_amt;
        });

        // Convert object to an array and include all group keys dynamically
        const dataArray = Object.values(harvestData).map(entry => {
            if (harvestView === 'zone_id') {
                allZoneIds.forEach(zone => {
                    if (!(zone in entry)) entry[zone] = 0;
                });
            } else if (harvestView === 'plant_type') {
                allPlantTypes.forEach(type => {
                    if (!(type in entry)) entry[type] = 0;
                });
            }
            return entry;
        });

        return dataArray.sort((a, b) => new Date(a.monthYear) - new Date(b.monthYear));
    };

    const stackedBarData = generateStackedBarData();
    const salesData = generateSalesData();
    const harvestData = generateHarvestData();

    // Custom function to handle tab change
    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    return (
        <Box>
            {/* Cards Section */}
            <Grid container spacing={2} mb={2}>
                <Grid item xs={12} sm={3}>
                    <MetricsCard
                        title="Total Harvests"
                        value={totalHarvests.total}
                        change={totalHarvests.change}
                        isIncrease={totalHarvests.isIncrease}
                        icon={<AgricultureIcon sx={{ color: '#FFA500' }} />}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <MetricsCard
                        title="Total Sales"
                        value={totalSales.total}
                        change={totalSales.change}
                        isIncrease={totalSales.isIncrease}
                        icon={<AttachMoneyIcon sx={{ color: '#00ff00' }} />}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <MetricsCard
                        title="Total Costs"
                        value={totalCosts.totalCost}
                        change={totalCosts.change}
                        isIncrease={totalCosts.isIncrease}
                        icon={<MoneyOffIcon sx={{ color: '#FF4500' }} />}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <MetricsCard
                        title="Post-Harvest Loss (%)"
                        value={postHarvestLoss.totalLoss}
                        change={postHarvestLoss.change}
                        isIncrease={postHarvestLoss.isIncrease}
                        icon={<ErrorOutlineIcon sx={{ color: '#FF4500' }} />}
                    />
                </Grid>
            </Grid>

            {/* Charts Section */}
            {isSmallScreen ? (
                <>
                    {/* Use Tabs for small screens */}
                    <Tabs value={tabIndex} onChange={handleTabChange} centered>
                        <Tab label="Sales Chart" />
                        <Tab label="Harvests Chart" />
                    </Tabs>
                    {tabIndex === 0 && (
                        <SalesChart data={salesData} salesView={salesView} setSalesView={setSalesView} />
                    )}
                    {tabIndex === 1 && (
                        <CostsBarChart data={stackedBarData} />
                    )}
                </>
            ) : (
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <SalesChart data={salesData} salesView={salesView} setSalesView={setSalesView} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <HarvestsChart data={harvestData} harvestView={harvestView} setHarvestView={setHarvestView} />
                        <CostsBarChart data={stackedBarData} />
                    </Grid>
                </Grid>

            )}
        </Box>
    );
};

export default FarmDetailsPage;
