import React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { Card, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { axisClasses } from '@mui/x-charts/ChartsAxis';

// Custom color palette for each cost category
const colors = {
    fertilizerCost: '#82ca9d',
    fungicideCost: '#8884d8',
    insecticideCost: '#ff7300',
    herbicideCost: '#ffbb28',
};

// Custom theme
const theme = createTheme({
    palette: {
        mode: 'dark',
    },
});

// Chart settings
const chartSettings = {
    yAxis: [
        {
            label: 'Cost (NGN)',
        },
    ],
    width: 800,
    height: 400,
    legend: {
        direction: 'row',
        position: {
            vertical: 'bottom', // Position the legend above the chart
            horizontal: 'center',
        },
        itemMargin: { right: 8, top: 8 }, // Add margin between legend items
        fontSize: '0.8rem', // Make legend text smaller
    },
    margin: {
        top: 20,
        bottom: 100,
        left: 100
    },
    sx: {
        [`.${axisClasses.left} .${axisClasses.label}`]: {
            transform: 'translate(-50px, 0)',
        },
    },
};

const CostsBarChart = ({ data }) => {
    return (
        <ThemeProvider theme={theme}>
            <Card sx={{ backgroundColor: '#1e1e1e', padding: 2, borderRadius: 4, boxShadow: 4 }}>
                <Typography variant="h6" color="white" sx={{ mb: 2 }}>Annual Costs by Category</Typography>
                <BarChart
                    dataset={data}
                    xAxis={[
                        {
                            scaleType: 'band',
                            dataKey: 'year',
                            label: 'Year',
                            tickLine: true,
                            stroke: '#ffffff',
                        },
                    ]}
                    series={[
                        {
                            dataKey: 'fertilizerCost',
                            label: 'Fertilizer Cost',
                            color: colors.fertilizerCost,
                        },
                        {
                            dataKey: 'fungicideCost',
                            label: 'Fungicide Cost',
                            color: colors.fungicideCost,
                        },
                        {
                            dataKey: 'insecticideCost',
                            label: 'Insecticide Cost',
                            color: colors.insecticideCost,
                        },
                        {
                            dataKey: 'herbicideCost',
                            label: 'Herbicide Cost',
                            color: colors.herbicideCost,
                        },
                    ]}
                    {...chartSettings}
                />
            </Card>
        </ThemeProvider>
    );
};

export default CostsBarChart;
