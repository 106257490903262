import React, { useState, useEffect } from 'react';
import {
    AppBar,
    Toolbar,
    Box,
    Container,
    Button,
    TextField,
    InputAdornment,
    createTheme,
    ThemeProvider,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import { useFarmData } from '../context/FarmDataContext';

const FarmsNav = ({ farmLocations = [], farm_id, idToken }) => {
    const [search, setSearch] = useState('');
    const [filteredFarms, setFilteredFarms] = useState([]);
    const navigate = useNavigate();
    const { fetchFarmData } = useFarmData();

    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
            primary: {
                main: '#90caf9',
            },
            secondary: {
                main: '#f48fb1',
            },
            background: {
                default: '#121212',
                paper: '#1e1e1e',
            },
            text: {
                primary: '#ffffff',
                secondary: '#b0b0b0',
            },
        },
    });

    // Filter farms based on the search query and reorder them
    useEffect(() => {
        if (farmLocations.length > 0) {
            let visibleFarms = farmLocations.filter((farm) =>
                farm.name.toLowerCase().includes(search.toLowerCase())
            );

            // Reorder to put the selected farm first
            if (farm_id) {
                visibleFarms = [
                    ...visibleFarms.filter((farm) => farm.id === farm_id),
                    ...visibleFarms.filter((farm) => farm.id !== farm_id),
                ];
            }

            setFilteredFarms(visibleFarms);
        }
    }, [search, farmLocations, farm_id]);

    // Navigate to the selected farm's details page
    const handleFarmClick = (farm) => {
        fetchFarmData(farm.id, idToken);
        navigate(`/farm/${farm.id}`, {
            state: { idToken, zones: farm.zones, farmLocations },
        });
    };

    return (
        <ThemeProvider theme={darkTheme}>
            <AppBar position="static" color="primary">
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        {/* Scrollable Farm Names */}
                        <Box
                            sx={{
                                flexGrow: 1,
                                marginRight: 2,
                                display: 'flex',
                                overflowX: 'auto',
                                ml: 2,
                                gap: 2,
                                '&::-webkit-scrollbar': {
                                    height: 6,
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: darkTheme.palette.divider,
                                    borderRadius: 3,
                                },
                            }}
                        >
                            {filteredFarms.map((farm) => (
                                <Button
                                    key={farm.id}
                                    onClick={() => handleFarmClick(farm)}
                                    variant={farm.id === farm_id ? 'contained' : 'outlined'}
                                    color={farm.id === farm_id ? 'secondary' : 'inherit'}
                                    sx={{
                                        flexShrink: 0,
                                        whiteSpace: 'nowrap',
                                        textTransform: 'capitalize',
                                        color:
                                            farm.id === farm_id
                                                ? darkTheme.palette.text.primary
                                                : darkTheme.palette.text.secondary,
                                        backgroundColor:
                                            farm.id === farm_id
                                                ? darkTheme.palette.secondary.main
                                                : 'transparent',
                                        '&:hover': {
                                            backgroundColor:
                                                farm.id === farm_id
                                                    ? darkTheme.palette.secondary.dark
                                                    : darkTheme.palette.action.hover,
                                        },
                                    }}
                                >
                                    {farm.name}
                                </Button>
                            ))}
                        </Box>
                        {/* Search Bar */}
                        <TextField
                            variant="outlined"
                            size="small"
                            placeholder="Search Farms"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            sx={{
                                flexShrink: 0,
                                width: '25%',
                                backgroundColor: darkTheme.palette.background.paper,
                                borderRadius: darkTheme.shape.borderRadius,
                                input: {
                                    color: darkTheme.palette.text.primary,
                                },
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon sx={{ color: darkTheme.palette.text.secondary }} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Toolbar>
                </Container>
            </AppBar>
        </ThemeProvider>
    );
};

export default FarmsNav;
