import React, { useState, useEffect, useMemo } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { cheerfulFiestaPalette } from '@mui/x-charts/colorPalettes';
import { PieChart } from '@mui/x-charts/PieChart';
import { Button, ButtonGroup, Box, Typography, Card, Select, MenuItem } from '@mui/material';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';

// Register the required AG-Grid modules
ModuleRegistry.registerModules([ClientSideRowModelModule]);

// Custom theme for the chart
const theme = createTheme({
    palette: {
        mode: 'dark',
    },
});

// Custom color palette
const currencyFormatter = (number) => {
    return 'NGN' + Intl.NumberFormat('us').format(number).toString();
};

// Function to extract unique years from the sales data
const extractAvailableYears = (data) => {
    const years = new Set(data.map(entry => entry.year));
    return Array.from(years).sort(); // Return sorted array of unique years
};

const SalesChart = ({ data, salesView, setSalesView }) => {
    const currentYear = new Date().getFullYear();
    const [selectedYear, setSelectedYear] = useState(currentYear);
    const [availableYears, setAvailableYears] = useState([]);

    // Extract available years when the component mounts or data changes
    useEffect(() => {
        const years = extractAvailableYears(data);
        setAvailableYears(years);
        if (!years.includes(currentYear)) {
            setSelectedYear(years[0]);
        }
    }, [data]);

    // Calculate the total value for the selected year
    const filteredData = data.filter(entry => entry.year === selectedYear);
    const totalValue = filteredData.reduce((acc, entry) => acc + entry.value, 0);

    // Format data based on the selected year
    const formattedData = filteredData
        .map((entry, index) => ({
            id: `${entry.year}-${entry.name}`,
            name: entry.name,
            label: entry.name,
            value: entry.value,
            percentage: ((entry.value / totalValue) * 100).toFixed(1),
        }))
        .sort((a, b) => b.percentage - a.percentage);

    // Legend placement configuration
    const legendPlacement = {
        slotProps: {
            legend: {
                position: {
                    vertical: 'middle',
                    horizontal: 'right',
                },
                direction: 'column',
                itemGap: 5,
            },
        },
        margin: {
            top: 20,
            right: 100,
        },
    };

    // Define columns for AG-Grid with flex to fill the available space
    const columnDefs = useMemo(() => [
        { headerName: 'Category', field: 'name', sortable: true, filter: true, flex: 1 },
        {
            headerName: 'Amount',
            field: 'value',
            sortable: true,
            filter: 'agNumberColumnFilter',
            valueFormatter: ({ value }) => (value != null ? currencyFormatter(value) : 'N/A'),
            flex: 1,
        },
        {
            headerName: 'Share (%)',
            field: 'percentage',
            sortable: true,
            filter: 'agNumberColumnFilter',
            valueFormatter: ({ value }) => (value != null ? `${value}%` : 'N/A'),
            flex: 1,
        },
    ], []);

    const defaultColDef = useMemo(() => ({
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        resizable: true,
    }), []);

    return (
        <ThemeProvider theme={theme}>
            <Card sx={{ backgroundColor: '#1e1e1e', padding: 2, borderRadius: 4, boxShadow: 4 }}>
                {/* Header with year selection */}
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Typography variant="h6" color="white">
                        Sales by {salesView === 'zone_id' ? 'Zone ID' : 'Plant Type'}
                    </Typography>
                    <ButtonGroup variant="contained" size="small">
                        <Button onClick={() => setSalesView('plant_type')} variant={salesView === 'plant_type' ? 'contained' : 'outlined'}>
                            Plant Type
                        </Button>
                        <Button onClick={() => setSalesView('zone_id')} variant={salesView === 'zone_id' ? 'contained' : 'outlined'}>
                            Zone ID
                        </Button>
                    </ButtonGroup>
                    <Select
                        value={selectedYear}
                        onChange={(e) => setSelectedYear(e.target.value)}
                        sx={{ color: 'white', backgroundColor: '#333', borderRadius: 2 }}
                    >
                        {availableYears.map(year => (
                            <MenuItem key={year} value={year}>
                                {year}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
                {/* Pie Chart */}
                <Box sx={{ mt: 3, mb: 2 }}>
                    <PieChart
                        series={[
                            {
                                data: formattedData,
                                paddingAngle: 5,
                                cornerRadius: 8,
                                innerRadius: 30,
                                outerRadius: 100,
                                startAngle: -45,
                                endAngle: 225,
                                highlightScope: { fade: 'global', highlight: 'item' },
                                faded: { innerRadius: 30, additionalRadius: -30, color: 'gray'},
                            },
                        ]}
                        width={700}
                        height={300}
                        // Legend positioning and styling
                        colors={cheerfulFiestaPalette(theme.palette.mode)}
                        {...legendPlacement}
                    />
                </Box>

                {/* AG-Grid for displaying and managing categories */}
                <Box
                    className="ag-theme-quartz"
                    sx={{
                        mt: 2, '--ag-foreground-color': 'white', '--ag-background-color': '#1e1e1e', flexGrow: 1,
                        overflow: 'auto',
                        height: 'calc(100% - 320px)',
                    }}
                >
                    <AgGridReact
                        rowData={formattedData}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        rowSelection="multiple"
                        pagination={true}
                        paginationPageSize={5}
                        domLayout="autoHeight"
                    />
                </Box>
            </Card>
        </ThemeProvider>
    );
};

export default SalesChart;
