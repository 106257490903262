
import React, { useMemo } from 'react';
import { Box, Tabs, Tab, createTheme, ThemeProvider } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useFarmData } from '../context/FarmDataContext';

const FinancePage = () => {
    const { farmData } = useFarmData(); // Access data from context
    const [tabIndex, setTabIndex] = React.useState(0);

    // Define a dark theme
    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
            primary: { main: '#90caf9' },
            secondary: { main: '#f48fb1' },
            background: {
                default: '#121212',
                paper: '#1e1e1e',
            },
            text: {
                primary: '#ffffff',
                secondary: '#b0b0b0',
            },
        },
    });

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const profitLossData = useMemo(() => {
        if (!farmData) return { rows: [], columns: [] };

        const years = new Set();
        const categories = [
            'Harvest',
            'Sales',
            'Seed Cost',
            'Fertilizer Cost',
            'Fungicide Cost',
            'Herbicide Cost',
            'Insecticide Cost',
            'Gross Profit',
            'Gross Profit %',
        ];

        const groupedData = {};

        const addCost = (year, category, amount, record) => {
            if (!groupedData[category]) {
                groupedData[category] = {};
            }
            if (!groupedData[category][year]) {
                groupedData[category][year] = { total: 0, records: [] };
            }
            groupedData[category][year].total += amount;
            if (record) groupedData[category][year].records.push(record);
        };

        // Process harvest data
        farmData.harvests.forEach((harvest) => {
            const year = new Date(harvest.date).getFullYear();
            years.add(year);
            addCost(year, 'Harvest', harvest.harvest_amt || 0, harvest);
        });

        // Process sales data
        farmData.sales.forEach((sale) => {
            const year = new Date(sale.date).getFullYear();
            years.add(year);
            addCost(year, 'Sales', sale.sale_amt || 0, sale);
        });

        // Process event costs
        ['fertilizerEvents', 'fungicideEvents', 'insecticideEvents', 'herbicideEvents'].forEach((eventType, idx) => {
            const category = categories[3 + idx];
            farmData[eventType].forEach((event) => {
                const year = new Date(event.date).getFullYear();
                years.add(year);
                addCost(year, category, event.event_cost || 0, event);
            });
        });

        // Process seed costs
        farmData.crops.forEach((crop) => {
            const date = new Date(crop.transplant_date || crop.planting_date);
            if (isNaN(date.getTime())) {
                console.log('Invalid date for crop (both transplant_date and planting_date are missing or invalid):', crop);
                return; // Skip invalid records
            }
            const year = date.getFullYear();
            years.add(year);
            const seedCost = (crop.units_planted || 0) * (crop.unit_cost || 0);
            addCost(year, 'Seed Cost', seedCost, crop);
        });

        // Calculate Gross Profit and Gross Profit % for each year
        Array.from(years).forEach((year) => {
            const sales = groupedData['Sales']?.[year]?.total || 0;
            const totalCosts =
                (groupedData['Fertilizer Cost']?.[year]?.total || 0) +
                (groupedData['Fungicide Cost']?.[year]?.total || 0) +
                (groupedData['Insecticide Cost']?.[year]?.total || 0) +
                (groupedData['Herbicide Cost']?.[year]?.total || 0) +
                (groupedData['Seed Cost']?.[year]?.total || 0);

            const grossProfit = sales - totalCosts;
            addCost(year, 'Gross Profit', grossProfit);

            // Calculate Gross Profit %
            const grossProfitPercentage = sales > 0 ? (grossProfit / sales) * 100 : 0;
            addCost(year, 'Gross Profit %', grossProfitPercentage);
        });

        // Convert grouped data to pivoted rows
        const rows = categories.map((category, idx) => {
            const row = { id: idx + 1, category };
            Array.from(years).forEach((year) => {
                row[year] = groupedData[category]?.[year]?.total || 0;
            });
            return row;
        });

        return { rows, columns: Array.from(years), groupedData };
    }, [farmData]);

    // Cash Flow Statement Data
    const cashFlowData = useMemo(() => {
        if (!profitLossData) return { rows: [], columns: [] };

        const years = profitLossData.columns; // Reuse P&L years
        const categories = [
            'Operating Activities',
            'Investing Activities',
            'Financing Activities',
            'Net Cash Flow',
        ];

        const groupedData = {};

        const addFlow = (year, category, amount) => {
            if (!groupedData[category]) {
                groupedData[category] = {};
            }
            groupedData[category][year] = (groupedData[category][year] || 0) + amount;
        };

        // Map Gross Profit to Operating Activities
        profitLossData.rows.forEach((row) => {
            if (row.category === 'Gross Profit') {
                years.forEach((year) => {
                    addFlow(year, 'Operating Activities', row[year] || 0);
                });
            }
        });

        // Placeholder: Add manual entries for Investing and Financing activities
        years.forEach((year) => {
            addFlow(year, 'Investing Activities', -5000); // Example: Machinery purchases
            addFlow(year, 'Financing Activities', 10000); // Example: Loan proceeds

            // Calculate Net Cash Flow
            const operating = groupedData['Operating Activities'][year] || 0;
            const investing = groupedData['Investing Activities'][year] || 0;
            const financing = groupedData['Financing Activities'][year] || 0;
            addFlow(year, 'Net Cash Flow', operating + investing + financing);
        });

        const rows = categories.map((category, idx) => {
            const row = { id: idx + 1, category };
            years.forEach((year) => {
                row[year] = groupedData[category]?.[year] || 0;
            });
            return row;
        });

        return { rows, columns: years };
    }, [profitLossData]);

    // Balance Sheet Data
    const balanceSheetData = useMemo(() => {
        if (!cashFlowData) return { rows: [], columns: [] };

        const years = cashFlowData.columns; // Reuse Cash Flow years
        const categories = [
            { name: 'Assets', subcategories: ['Cash', 'Accounts Receivable', 'PP&E (Machinery, Land)'] },
            { name: 'Liabilities', subcategories: ['Accounts Payable', 'Long-Term Debt'] },
            { name: 'Equity', subcategories: ['Owner’s Equity'] },
        ];

        const groupedData = {};

        const addValue = (year, category, subcategory, amount) => {
            if (!groupedData[category]) {
                groupedData[category] = {};
            }
            if (!groupedData[category][subcategory]) {
                groupedData[category][subcategory] = {};
            }
            groupedData[category][subcategory][year] = (groupedData[category][subcategory][year] || 0) + amount;
        };

        // Map Net Cash Flow to Assets -> Cash
        cashFlowData.rows.forEach((row) => {
            if (row.category === 'Net Cash Flow') {
                years.forEach((year) => {
                    addValue(year, 'Assets', 'Cash', row[year] || 0);
                });
            }
        });

        // Placeholder: Add manual entries for other subcategories
        years.forEach((year) => {
            addValue(year, 'Assets', 'PP&E (Machinery, Land)', 50000); // Example: Fixed assets
            addValue(year, 'Liabilities', 'Accounts Payable', 15000); // Example: Payables
            addValue(year, 'Liabilities', 'Long-Term Debt', 30000); // Example: Debt
            addValue(year, 'Equity', 'Owner’s Equity', 10000); // Example: Equity balancing
        });

        const rows = [];
        categories.forEach((category, idx) => {
            rows.push({ id: idx + 1, category: category.name });
            category.subcategories.forEach((sub, subIdx) => {
                const row = { id: `${idx + 1}-${subIdx + 1}`, category: `  ${sub}` };
                years.forEach((year) => {
                    row[year] = groupedData[category.name]?.[sub]?.[year] || 0;
                });
                rows.push(row);
            });
        });

        return { rows, columns: years };
    }, [cashFlowData]);

    const renderTable = (data) => (
        <Box sx={{ height: 600, mt: 2 }}>
            <DataGridPro
                rows={data.rows}
                columns={[
                    { field: 'category', headerName: 'Category', width: 200 },
                    ...data.columns.map((year) => ({
                        field: `${year}`,
                        headerName: `${year}`,
                        width: 150,
                    })),
                ]}
                sx={{
                    bgcolor: darkTheme.palette.background.default,
                    color: darkTheme.palette.text.primary,
                    '& .MuiDataGrid-columnHeaders': {
                        bgcolor: darkTheme.palette.background.paper,
                        color: darkTheme.palette.text.primary,
                    },
                    '& .MuiDataGrid-cell': {
                        color: darkTheme.palette.text.primary,
                    },
                }}
                autoHeight
            />
        </Box>
    );

    return (
        <ThemeProvider theme={darkTheme}>
            <Box sx={{ p: 2, bgcolor: darkTheme.palette.background.default, color: darkTheme.palette.text.primary }}>
                <Tabs
                    value={tabIndex}
                    onChange={handleTabChange}
                    centered
                    textColor="secondary"
                    indicatorColor="secondary"
                >
                    <Tab label="Profit & Loss" />
                    <Tab label="Cash Flow Statement" />
                    <Tab label="Balance Sheet" />
                </Tabs>
                {tabIndex === 0 && renderTable(profitLossData)}
                {tabIndex === 1 && renderTable(cashFlowData)}
                {tabIndex === 2 && renderTable(balanceSheetData)}
            </Box>
        </ThemeProvider>
    );
};

export default FinancePage;
