import React, { useState, useMemo } from "react";
import {
    AppBar,
    Box,
    Typography,
    Collapse,
    Card,
    CardContent,
    List,
    ListItemButton,
    Divider,
    LinearProgress,
    IconButton,
} from "@mui/material";
import DashboardIcon from '@mui/icons-material/Dashboard';
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const ZoneDrawer = ({ zones = [], crops = [], harvests = [], drawerExpanded, setDrawerExpanded }) => {
    const [expandedZones, setExpandedZones] = useState({});

    const toggleZone = (zoneId) => {
        setExpandedZones((prev) => ({
            ...prev,
            [zoneId]: !prev[zoneId],
        }));
    };

    const sortedZones = useMemo(() => {
        return zones
            .filter((zone) => !zone.deleted)
            .sort((a, b) => a.name.localeCompare(b.name));
    }, [zones]);

    const sortedCrops = useMemo(() => {
        return crops
            .filter((crop) => !crop.deleted && !crop.terminated)
            .sort((a, b) => {
                const dateA = new Date(a.transplant_date || a.planting_date);
                const dateB = new Date(b.transplant_date || b.planting_date);
                return dateA - dateB;
            });
    }, [crops]);

    const calculateCropData = (crop) => {
        const cropHarvests = harvests.filter((harvest) => harvest.crop_id === crop.id);
        const totalYield = cropHarvests.reduce((sum, h) => sum + (h.harvest_amt || 0), 0);
        const lastHarvestDate = cropHarvests.reduce((latest, h) => {
            const date = new Date(h.date);
            return date > latest ? date : latest;
        }, new Date(crop.planting_date));
        const plot = zones.flatMap((zone) => zone.plots).find((plot) => plot.id === crop.plot_id);
        const actualYield = plot && plot.area > 0 ? (totalYield / plot.area).toFixed(2) : 0;

        return {
            plotName: plot?.name || "N/A",
            plotArea: plot?.area || "N/A",
            plotAreaUnit: plot?.area_unit || "N/A",
            varietyName: crop.variety_name_cl || crop.variety_name || "Unknown",
            plantType: crop.plant_type_cl || crop.plant_type || "Unknown",
            plantingDate: crop.planting_date || "N/A",
            transplantDate: crop.transplant_date || "N/A",
            lastHarvestDate: cropHarvests.length ? lastHarvestDate.toISOString().split('T')[0] : "N/A",
            targetYield: crop.target_yield || 0,
            actualYield,
        };
    };

    return (
        <AppBar
            position="fixed"
            sx={{
                backgroundColor: "#333",
                width: drawerExpanded ? "300px" : "80px",
                height: "100vh",
                transition: "width 0.3s",
                display: "flex",
                flexDirection: "column",
                alignItems: drawerExpanded ? "flex-start" : "center",
                justifyContent: "flex-start",
                right: 0,
                zIndex: 1201,
                paddingTop: "16px",
            }}
        >
            {/* Drawer Toggle Button */}
            <IconButton
                color="inherit"
                aria-label="toggle drawer"
                onClick={() => setDrawerExpanded(!drawerExpanded)}
                sx={{ marginBottom: "16px", alignSelf: drawerExpanded ? "flex-start" : "center" }}
            >
                <DashboardIcon />
            </IconButton>

            {/* Drawer Content */}
            {drawerExpanded && (
                <Box sx={{ width: "100%", overflowY: "auto", padding: "16px" }}>
                    <Typography
                        variant="h6"
                        sx={{ color: "white", textAlign: "left", marginBottom: "8px" }}
                    >
                        Zone Overview
                    </Typography>
                    <Divider sx={{ backgroundColor: "#555", marginBottom: "8px" }} />

                    <List>
                        {sortedZones.map((zone) => (
                            <Box key={zone.id}>
                                {/* Zone Header */}
                                <ListItemButton onClick={() => toggleZone(zone.id)}>
                                    <Typography variant="body1" sx={{ color: "white", flex: 1 }}>
                                        {zone.name}
                                    </Typography>
                                    {expandedZones[zone.id] ? (
                                        <ExpandLessIcon sx={{ color: "white" }} />
                                    ) : (
                                        <ExpandMoreIcon sx={{ color: "white" }} />
                                    )}
                                </ListItemButton>
                                <Divider sx={{ backgroundColor: "#555" }} />

                                {/* Zone Content */}
                                <Collapse in={expandedZones[zone.id]} timeout="auto" unmountOnExit>
                                    <Box sx={{ paddingLeft: "16px" }}>
                                        {sortedCrops
                                            .filter((crop) => crop.zone_id === zone.id)
                                            .map((crop) => {
                                                const cropData = calculateCropData(crop);

                                                return (
                                                    <Card
                                                        key={crop.id}
                                                        sx={{
                                                            backgroundColor: "#444",
                                                            color: "white",
                                                            marginBottom: "8px",
                                                        }}
                                                    >
                                                        <CardContent>
                                                            <Typography variant="h6">{cropData.plotName}</Typography>
                                                            <Typography variant="body2">
                                                                Planting Date: {cropData.plantingDate}
                                                            </Typography>
                                                            <Typography variant="body2">
                                                                Transplant Date: {cropData.transplantDate}
                                                            </Typography>
                                                            <Typography variant="body2">
                                                                Last Harvest: {cropData.lastHarvestDate}
                                                            </Typography>
                                                            <Typography variant="body2">
                                                                Variety: {cropData.varietyName}
                                                            </Typography>
                                                            <Typography variant="body2">
                                                                Plant Type: {cropData.plantType}
                                                            </Typography>
                                                            <Typography variant="body2">
                                                                Actual Yield: {cropData.actualYield} kg/m²
                                                            </Typography>
                                                            <Typography variant="body2">
                                                                Target Yield: {cropData.targetYield} kg/m²
                                                            </Typography>
                                                            <Box sx={{ marginTop: "8px" }}>
                                                                <LinearProgress
                                                                    variant="determinate"
                                                                    value={
                                                                        cropData.targetYield
                                                                            ? Math.min(
                                                                                  (cropData.actualYield /
                                                                                      cropData.targetYield) *
                                                                                      100,
                                                                                  100
                                                                              )
                                                                            : 0
                                                                    }
                                                                    sx={{ height: 10, borderRadius: 5 }}
                                                                />
                                                            </Box>
                                                        </CardContent>
                                                    </Card>
                                                );
                                            })}
                                    </Box>
                                </Collapse>
                            </Box>
                        ))}
                    </List>
                </Box>
            )}
        </AppBar>
    );
};

export default ZoneDrawer;