import React, { createContext, useContext, useState } from 'react';

// Create the context
const FarmDataContext = createContext();

// Provider component
export const FarmDataProvider = ({ children }) => {
    const [farmData, setFarmData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // Function to fetch farm data
    const fetchFarmData = async (farm_id, idToken) => {

        setLoading(true);
        try {
            const endpoints = [
                `/farm/${farm_id}/crops`,
                `/farm/${farm_id}/fertilizer_events`,
                `/farm/${farm_id}/fungicide_events`,
                `/farm/${farm_id}/insecticide_events`,
                `/farm/${farm_id}/herbicide_events`,
                `/farm/${farm_id}/harvests`,
                `/farm/${farm_id}/sales`
            ];

            const requests = endpoints.map((endpoint) =>
                fetch(`https://api.isabel.io${endpoint}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${idToken}`,
                    },
                }).then((res) => res.json())
            );

            const results = await Promise.all(requests);
            console.log(results);
            const [
                crops,
                fertilizerEvents,
                fungicideEvents,
                insecticideEvents,
                herbicideEvents,
                harvests,
                sales,
            ] = results;

            setFarmData({
                crops,
                fertilizerEvents,
                fungicideEvents,
                insecticideEvents,
                herbicideEvents,
                harvests,
                sales,
            });
        } catch (err) {
            console.error('Error fetching farm data:', err);
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <FarmDataContext.Provider value={{ farmData, fetchFarmData, loading, error }}>
            {children}
        </FarmDataContext.Provider>
    );
};

// Custom hook to use the FarmDataContext
export const useFarmData = () => {
    const context = useContext(FarmDataContext);
    if (!context) {
        throw new Error('useFarmData must be used within a FarmDataProvider');
    }
    return context;
};
