import React, { useState, useEffect } from "react";
import {
    AppBar,
    Box,
    IconButton,
    Avatar,
    Tooltip,
    Typography,
    Menu,
    MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MapIcon from "@mui/icons-material/Map";
import SpaIcon from "@mui/icons-material/Spa";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import BarChartIcon from "@mui/icons-material/BarChart";
import { useNavigate } from "react-router-dom";

const FarmSideBar = ({
    onLogout,
    idToken,
    userData = {},
    menuExpanded,
    setMenuExpanded,
    farm_id,
    zones = [],
    farmLocations = [],
}) => {
    const [anchorElUser, setAnchorElUser] = useState(null);
    const navigate = useNavigate();
    const [selectedFarm, setSelectedFarm] = useState(null);

    // Set the selected farm based on farm_id
    useEffect(() => {
        const farm = farmLocations?.find((f) => f.id === farm_id);
        setSelectedFarm(farm || null);
    }, [farm_id, farmLocations]);

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleMenuToggle = () => {
        setMenuExpanded(!menuExpanded);
    };

    const handleCopyIdToken = async () => {
        try {
            await navigator.clipboard.writeText(`Bearer ${idToken}`);
            alert("ID Token copied to clipboard");
        } catch (err) {
            console.error("Failed to copy ID token:", err);
        }
    };

    const handleNavigation = (path) => {
        if (farm_id) {
            navigate(path, {
                state: { idToken, farmLocations, userData, zones},
            });
        } else {
            console.error("Farm ID is undefined. Navigation canceled.");
        }
    };

    const avatarLetter = userData?.name?.[0]?.toUpperCase() || "?";

    return (
        <AppBar
            position="fixed"
            sx={{
                backgroundColor: "#333",
                width: menuExpanded ? "200px" : "80px",
                height: "100vh",
                transition: "width 0.3s",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                paddingTop: "16px",
                left: 0,
            }}
        >
            {/* Menu Toggle Button */}
            <IconButton
                color="inherit"
                aria-label="menu"
                onClick={handleMenuToggle}
                sx={{ marginBottom: "16px" }}
            >
                <MenuIcon />
            </IconButton>

            {/* Map Icon */}
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: menuExpanded ? "flex-start" : "center",
                    width: "100%",
                    paddingLeft: menuExpanded ? "16px" : 0,
                    marginBottom: "32px",
                }}
            >
                <IconButton
                    color="inherit"
                    aria-label="map"
                    onClick={() => handleNavigation(`/home`)}
                >
                    <MapIcon />
                </IconButton>
                {menuExpanded && (
                    <Typography variant="body1" sx={{ ml: 1 }}>
                        Map
                    </Typography>
                )}
            </Box>

            {/* Grouped Icons */}
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: menuExpanded ? "flex-start" : "center",
                    width: "100%",
                    gap: 2,
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: menuExpanded ? "flex-start" : "center",
                        width: "100%",
                        paddingLeft: menuExpanded ? "16px" : 0,
                    }}
                >
                    <IconButton
                        color="inherit"
                        aria-label="farm-details"
                        onClick={() => {
                            console.log("Navigating to:", `/farm/${farm_id}`);
                            handleNavigation(`/farm/${farm_id}`);
                        }}
                    >
                        <BarChartIcon />
                    </IconButton>
                    {menuExpanded && (
                        <Typography variant="body1" sx={{ ml: 1 }}>
                            Dashboard
                        </Typography>
                    )}
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: menuExpanded ? "flex-start" : "center",
                        width: "100%",
                        paddingLeft: menuExpanded ? "16px" : 0,
                    }}
                >
                    <IconButton
                        color="inherit"
                        aria-label="crops"
                        onClick={() => handleNavigation(`/crops/${farm_id}`)}
                    >
                        <SpaIcon />
                    </IconButton>
                    {menuExpanded && (
                        <Typography variant="body1" sx={{ ml: 1 }}>
                            Crops
                        </Typography>
                    )}
                </Box>

                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: menuExpanded ? "flex-start" : "center",
                        width: "100%",
                        paddingLeft: menuExpanded ? "16px" : 0,
                    }}
                >
                    <IconButton
                        color="inherit"
                        aria-label="finance"
                        onClick={() => handleNavigation(`/financials/${farm_id}`)}
                    >
                        <AttachMoneyIcon />
                    </IconButton>
                    {menuExpanded && (
                        <Typography variant="body1" sx={{ ml: 1 }}>
                            Finance
                        </Typography>
                    )}
                </Box>

                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: menuExpanded ? "flex-start" : "center",
                        width: "100%",
                        paddingLeft: menuExpanded ? "16px" : 0,
                    }}
                >
                    <IconButton
                        color="inherit"
                        aria-label="traceability"
                        onClick={() => handleNavigation(`/traceability/${farm_id}`)}
                    >
                        <ContentPasteSearchIcon />
                    </IconButton>
                    {menuExpanded && (
                        <Typography variant="body1" sx={{ ml: 1 }}>
                            Traceability
                        </Typography>
                    )}
                </Box>
            </Box>

            {/* User Avatar */}
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: menuExpanded ? "flex-start" : "center",
                    width: "100%",
                    paddingLeft: menuExpanded ? "16px" : 0,
                    marginTop: "auto",
                    marginBottom: "16px",
                }}
            >
                <Tooltip title="Open settings">
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                        <Avatar alt="User Avatar">{avatarLetter}</Avatar>
                    </IconButton>
                </Tooltip>
            </Box>

            {/* User Menu */}
            <Menu
                sx={{ mt: "-45px" }}
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                keepMounted
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                <MenuItem>{userData?.name || "Unknown User"}</MenuItem>
                <MenuItem
                    onClick={() => {
                        handleCopyIdToken();
                        handleCloseUserMenu();
                    }}
                >
                    <Typography textAlign="center">Copy ID Token</Typography>
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        onLogout();
                        handleCloseUserMenu();
                    }}
                >
                    <Typography textAlign="center">Logout</Typography>
                </MenuItem>
            </Menu>
        </AppBar>
    );
};

export default FarmSideBar;