import React, { useMemo, useCallback } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Box, Typography, CircularProgress, createTheme, ThemeProvider } from '@mui/material';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { useFarmData } from '../context/FarmDataContext';

const TraceabilityPage = () => {
    const { farm_id } = useParams();
    const location = useLocation();
    const idToken = location.state?.idToken;
    const { farmData, fetchFarmData, loading } = useFarmData();

    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
            primary: { main: '#90caf9' },
            secondary: { main: '#f48fb1' },
            background: {
                default: '#121212',
                paper: '#1e1e1e',
            },
            text: {
                primary: '#ffffff',
                secondary: '#b0b0b0',
            },
        },
    });

    React.useEffect(() => {
        if (idToken && !farmData) {
            fetchFarmData(farm_id, idToken);
        }
    }, [farm_id, idToken, farmData, fetchFarmData]);

    const salesRows = useMemo(() => {
        if (!farmData?.sales || !farmData?.harvests || !farmData?.crops) return [];

        return farmData.sales.map((sale, index) => {
            const harvest = farmData.harvests.find((h) => h.id === sale.harvest_id);
            const crop = farmData.crops.find((c) => c.id === harvest?.crop_id);

            return {
                id: index + 1,
                sale_id: sale.id,
                harvest_id: sale.harvest_id,
                date: sale.date,
                variety_name: crop?.variety_name_cl || crop?.variety_name,
                plant_type: crop?.plant_type_cl || crop?.plant_type,
                sale_amt: sale.sale_amt,
                sale_unit_type: sale.sale_unit_type,
                harvest_amt: sale.harvest_amt,
                harvest_unit_type: sale.harvest_unit_type,
                unit_price: sale.unit_price,
                zone_name: sale.zone?.name || 'Unknown',
                plot_name: sale.plot?.name || 'Unknown',
                crop_start_date: crop?.planting_date || null,
                harvest_date: harvest?.date || null,
            };
        });
    }, [farmData]);

    const activityColumns = [
        { field: 'id', headerName: 'Activity ID', width: 150 },
        { field: 'activity_type', headerName: 'Activity Type', width: 150 },
        { field: 'date', headerName: 'Date', width: 150 },
        { field: 'event_cost', headerName: 'Cost', width: 150 },
        { field: 'description', headerName: 'Description', flex: 1 },
    ];

    const getDetailPanelContent = useCallback(
        ({ row }) => {
            const cropStartDate = new Date(row.crop_start_date);
            const harvestDate = new Date(row.harvest_date);

            const activities = ['fertilizerEvents', 'fungicideEvents', 'insecticideEvents', 'herbicideEvents']
                .flatMap((activityType) =>
                    farmData[activityType]
                        .filter((event) => {
                            const eventDate = new Date(event.date);
                            return eventDate >= cropStartDate && eventDate <= harvestDate;
                        })
                        .map((event) => ({
                            id: event.id,
                            activity_type: activityType.slice(0, -6), // Remove "Events"
                            date: event.date,
                            event_cost: event.event_cost || 0,
                            description: event.description || '',
                        }))
                );

            return (
                <Box sx={{ p: 2, bgcolor: darkTheme.palette.background.paper }}>
                    <Typography variant="h6" color="textPrimary">
                        Related Activities
                    </Typography>
                    <Box sx={{ height: 300, mt: 2 }}>
                        <DataGridPro
                            rows={activities}
                            columns={activityColumns}
                            components={{ Toolbar: GridToolbar }}
                            autoHeight
                            sx={{
                                bgcolor: darkTheme.palette.background.default,
                                color: darkTheme.palette.text.primary,
                            }}
                        />
                    </Box>
                </Box>
            );
        },
        [farmData, darkTheme]
    );

    const getDetailPanelHeight = useCallback(() => 400, []);

    if (loading || !farmData) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <CircularProgress />
            </Box>
        );
    }

    return (
        <ThemeProvider theme={darkTheme}>
            <Box sx={{ p: 2, bgcolor: darkTheme.palette.background.default }}>
                <Typography variant="h4" color="primary" gutterBottom>
                    Sales Data
                </Typography>
                <Box sx={{ height: 600 }}>
                    <DataGridPro
                        rows={salesRows}
                        columns={[
                            { field: 'sale_id', headerName: 'Sale ID', width: 150 },
                            { field: 'harvest_id', headerName: 'Harvest ID', width: 150 },
                            { field: 'date', headerName: 'Date', width: 150 },
                            { field: 'variety_name', headerName: 'Variety Name', width: 150 },
                            { field: 'plant_type', headerName: 'Plant Type', width: 150 },
                            { field: 'sale_amt', headerName: 'Sale Amount', width: 150 },
                            { field: 'sale_unit_type', headerName: 'Sale Unit Type', width: 150 },
                            { field: 'harvest_amt', headerName: 'Harvest Amount', width: 150 },
                            { field: 'harvest_unit_type', headerName: 'Harvest Unit Type', width: 150 },
                            { field: 'unit_price', headerName: 'Unit Price', width: 150 },
                            { field: 'zone_name', headerName: 'Zone Name', width: 150 },
                            { field: 'plot_name', headerName: 'Plot Name', width: 150 },
                        ]}
                        getDetailPanelContent={getDetailPanelContent}
                        getDetailPanelHeight={getDetailPanelHeight}
                        components={{ Toolbar: GridToolbar }}
                        pageSize={10}
                        rowsPerPageOptions={[10, 20, 50]}
                        disableSelectionOnClick
                        sx={{
                            bgcolor: darkTheme.palette.background.default,
                            color: darkTheme.palette.text.primary,
                            '& .MuiDataGrid-columnHeaders': {
                                bgcolor: darkTheme.palette.background.paper,
                                color: darkTheme.palette.text.primary,
                            },
                            '& .MuiDataGrid-cell': {
                                color: darkTheme.palette.text.primary,
                            },
                        }}
                    />
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default TraceabilityPage;
