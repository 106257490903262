import React, { useEffect, useMemo } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Box, Typography, CircularProgress, createTheme, ThemeProvider } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useFarmData } from '../context/FarmDataContext';

const CropsPage = () => {
    const { farm_id } = useParams();
    const location = useLocation();
    const idToken = location.state?.idToken;
    const { farmData, fetchFarmData, loading } = useFarmData();

    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
            primary: { main: '#90caf9' },
            secondary: { main: '#f48fb1' },
            background: {
                default: '#121212',
                paper: '#1e1e1e',
            },
            text: {
                primary: '#ffffff',
                secondary: '#b0b0b0',
            },
        },
    });

    // Fetch farm data when component loads
    useEffect(() => {
        if (idToken && !farmData) {
            fetchFarmData(farm_id, idToken);
        }
    }, [farm_id, idToken, farmData, fetchFarmData]);

    const rows = useMemo(() => {
        if (!farmData?.crops || !farmData?.harvests || !farmData?.sales) return [];

        return farmData.crops.map((crop, index) => {
            const cropHarvests = farmData.harvests.filter(
                (harvest) => harvest.crop_id === crop.id
            );

            const totalYield = cropHarvests.reduce((sum, h) => sum + (h.harvest_amt || 0), 0);
            const totalSales = farmData.sales
                .filter((sale) => cropHarvests.some((h) => h.id === sale.harvest_id))
                .reduce((sum, sale) => sum + (sale.sale_amt || 0), 0);

            const lastHarvestDate = cropHarvests.reduce((latest, h) => {
                const date = new Date(h.date);
                return date > latest ? date : latest;
            }, new Date(crop.planting_date));

            const actualYield = crop.plot?.area
                ? (totalYield / crop.plot.area).toFixed(2)
                : 0;

            const yieldPerPlant = crop.units_planted
                ? (totalYield / crop.units_planted).toFixed(2)
                : 0;

            const duration = crop.transplant_date
                ? Math.ceil(
                    (new Date(lastHarvestDate) - new Date(crop.transplant_date)) /
                    (1000 * 60 * 60 * 24)
                )
                : null;

            return {
                id: index + 1,
                variety_name: crop.variety_name,
                plant_type: crop.plant_type,
                planting_date: crop.planting_date,
                transplant_date: crop.transplant_date,
                last_harvest_date: lastHarvestDate.toISOString().split('T')[0],
                units_planted: crop.units_planted,
                zone_name: crop.zone?.name || '',
                plot_name: crop.plot?.name || '',
                plot_area: crop.plot?.area || 0,
                plot_area_unit: crop.plot?.area_unit || '',
                target_yield: crop.target_yield || 0,
                target_yield_unit_type: crop.target_unit_type || '',
                total_yield: totalYield,
                total_sales: totalSales,
                actual_yield: actualYield,
                yield_per_plant: yieldPerPlant,
                duration,
            };
        });
    }, [farmData]);

    const columns = [
        { field: 'variety_name', headerName: 'Variety Name', width: 150 },
        { field: 'plant_type', headerName: 'Plant Type', width: 150 },
        { field: 'planting_date', headerName: 'Planting Date', width: 150 },
        { field: 'transplant_date', headerName: 'Transplant Date', width: 150 },
        { field: 'last_harvest_date', headerName: 'Last Harvest Date', width: 150 },
        { field: 'units_planted', headerName: 'Units Planted', width: 150 },
        { field: 'zone_name', headerName: 'Zone Name', width: 150 },
        { field: 'plot_name', headerName: 'Plot Name', width: 150 },
        { field: 'plot_area', headerName: 'Plot Area', width: 150 },
        { field: 'plot_area_unit', headerName: 'Plot Area Unit', width: 150 },
        { field: 'target_yield', headerName: 'Target Yield', width: 150 },
        { field: 'target_yield_unit_type', headerName: 'Target Yield Unit', width: 150 },
        { field: 'total_yield', headerName: 'Total Yield', width: 150 },
        { field: 'total_sales', headerName: 'Total Sales', width: 150 },
        { field: 'actual_yield', headerName: 'Actual Yield', width: 150 },
        { field: 'yield_per_plant', headerName: 'Yield Per Plant', width: 150 },
        { field: 'duration', headerName: 'Duration (Days)', width: 150 },
    ];

    if (loading || !farmData) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <CircularProgress />
            </Box>
        );
    }

    return (
        <ThemeProvider theme={darkTheme}>
            <Box sx={{ bgcolor: darkTheme.palette.background.default }}>
                <Typography variant="h4" color="primary" gutterBottom>
                    Crop Data
                </Typography>
                <Box sx={{ height: 600 }}>
                    <DataGridPro
                        rows={rows}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10, 20, 50]}
                        disableSelectionOnClick
                        sx={{
                            bgcolor: darkTheme.palette.background.default,
                            color: darkTheme.palette.text.primary,
                        }}
                    />
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default CropsPage;
