import React, { useEffect, useState } from "react";
import { Box, LinearProgress, Typography } from "@mui/material";
import { useNavigate, useLocation } from "react-router";
import { useUserAuth } from "../context/UserAuthContext";
import Header from "./Header";
import FullScreenMap from "./FullScreenMap";
import FarmsDropdowns from "./FarmsDropdowns";
import SearchBar from "./SearchBar";
import FarmsInfoPanel from "./FarmsInfoPanel"; // Import the FarmsInfoPanel (formerly RightDrawer)

const Home = () => {
  const { logOut, user } = useUserAuth();
  const navigate = useNavigate();
  const [idToken, setIdToken] = useState("");
  const location = useLocation();
  const { userData } = location.state || {}; // Access userData from location state
  const [farmLocations, setFarmLocations] = useState([]);
  const [progress, setProgress] = useState(0);  // Track progress percentage
  const [popupInfo, setPopupInfo] = useState(null);
  const [menuExpanded, setMenuExpanded] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");  // State for search input
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [selectedFarmTypes, setSelectedFarmTypes] = useState([]);
  const [isDataFetched, setIsDataFetched] = useState(false);  // Flag to check if data is ready
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);  // Control the drawer visibility

  const [viewState, setViewState] = useState({
    latitude: 37.7749,
    longitude: -122.4194,
    zoom: 10,
  });

  // Fetch ID Token when the user logs in
  useEffect(() => {
    const fetchIdToken = async () => {
      if (user) {
        try {
          const token = await user.getIdToken();
          setIdToken(token);
        } catch (error) {
          console.log("Error fetching ID token:", error);
        }
      }
    };

    fetchIdToken();
  }, [user]); // Only run this effect once when the user logs in

  // Fetch farm data based on the userData farms and their IDs
  useEffect(() => {
    const fetchFarmData = async () => {
      if (idToken && userData && userData.farms && userData.farms.length) {
        const totalFarms = userData.farms.length;
        const promises = userData.farms.map(async (farm, index) => {
          try {
            const response = await fetch(`https://api.isabel.io/farm/${farm.farm_id}`, {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${idToken}`,
              },
            });

            if (!response.ok) {
              if (response.status === 404) {
                console.log(`Farm ${farm.farm_id} not found (404).`);
                return null; // Ignore 404 errors
              } else {
                throw new Error(`Error fetching farm ${farm.farm_id}: ${response.statusText}`);
              }
            }

            const farmData = await response.json();
            console.log(farmData);
            const lat = parseCoordinate(farmData.lat);
            const lon = parseCoordinate(farmData.lon);

            // Update the progress percentage after each farm is fetched
            setProgress(prevProgress => prevProgress + (100 / totalFarms));

            return {
              id: farm.farm_id,
              lat,
              lon,
              area: farmData.area,
              area_unit: farmData.area_unit,
              type: farmData.farm_type,
              name: farmData.name,
              city: farmData.city,
              state: farmData.state,
              country: farmData.country,
              zones: farmData.zones,
              inputs: farmData.inputs,
              users: farmData.users
            };

          } catch (error) {
            console.log(`Error fetching farm ${farm.farm_id} data:`, error);
            return null;
          }
        });

        const farmResults = await Promise.all(promises);
        const validFarms = farmResults.filter((farm) => farm !== null);
        setFarmLocations(validFarms);

        // When all data is fetched, set the flag to true
        setIsDataFetched(true);

        if (validFarms.length > 0) {
          const firstFarm = validFarms[0];
          setViewState(prevState => ({
            ...prevState,
            latitude: firstFarm.lat,
            longitude: firstFarm.lon,
            zoom: 10,
          }));
        }
      }
    };

    if (idToken && userData) {
      fetchFarmData();
    }
  }, [idToken, userData]); // Depend only on idToken and userData

  // Function to parse the latitude and longitude values
  const parseCoordinate = (coordinateStr) => {
    if (!coordinateStr) return NaN;
    const cleanStr = coordinateStr.replace(/[^\d.-]/g, '');
    const direction = coordinateStr.slice(-1);
    let coordinate = parseFloat(cleanStr);
    if (direction === 'S' || direction === 'W') {
      coordinate = -coordinate;
    }
    return coordinate;
  };

  // Handle user logout
  const handleLogout = async () => {
    try {
      await logOut();
      navigate("/");
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <Box sx={{ height: "100vh", display: 'flex' }}>
      {/* Fixed Header */}
      <Box sx={{ zIndex: 1000, flexShrink: 0, height: '100vh', position: 'fixed', left: 0, top: 0 }}>
        <Header
          onLogout={handleLogout}
          userData={userData}
          menuExpanded={menuExpanded}
          setMenuExpanded={setMenuExpanded}  // Pass menu expansion state
          idToken={idToken}
        />
      </Box>

      {/* Main content (Progress bar or Map and Dropdowns) */}
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          ml: menuExpanded ? '200px' : '80px',  // Adjust the content based on menu width
          width: '100%',  // Adjust the width based on drawer state
          transition: 'width 0.3s',
          overflow: 'hidden',  // Ensure content does not overflow
        }}
      >
        {/* Show Progress Bar until data is fully fetched */}
        {!isDataFetched ? (
          <Box sx={{ width: '100%', mt: 4 }}>
            <Typography variant="h6" align="center">
              Fetching farm data...
            </Typography>
            <LinearProgress variant="determinate" value={progress} sx={{ mt: 2 }} />
          </Box>
        ) : (
          <>
            {/* Search Bar */}
            <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />

            {/* Farms Accordion Dropdowns */}
            <Box
              sx={{
                width: '100%',
                overflowY: 'auto',  // Make dropdowns scrollable
                flexShrink: 0,
                paddingLeft: 2,
                paddingRight: 2,
                maxHeight: '20vh',  // Limit the height of dropdowns to avoid screen overflow
              }}
            >
              <FarmsDropdowns
                farmLocations={farmLocations}
                selectedCountries={selectedCountries}
                setSelectedCountries={setSelectedCountries}
                selectedStates={selectedStates}
                setSelectedStates={setSelectedStates}
                selectedCities={selectedCities}
                setSelectedCities={setSelectedCities}
                selectedFarmTypes={selectedFarmTypes}
                setSelectedFarmTypes={setSelectedFarmTypes}
              />
            </Box>

            {/* Full-Screen Map */}
            <Box sx={{ flexGrow: 1, width: '100%', paddingTop: 2, position: 'relative', zIndex: 100 }}>
              <FullScreenMap
                viewState={viewState}
                setViewState={setViewState}
                farmLocations={farmLocations}
                selectedCountries={selectedCountries}
                selectedStates={selectedStates}
                selectedCities={selectedCities}
                selectedFarmTypes={selectedFarmTypes}
                searchQuery={searchQuery}
                popupInfo={popupInfo}
                setPopupInfo={setPopupInfo}
                apiKey={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
              />
            </Box>
          </>
        )}
      </Box>

      {/* Farms Info Panel */}
      <FarmsInfoPanel
        farmLocations={farmLocations}
        selectedCountries={selectedCountries}
        selectedStates={selectedStates}
        selectedCities={selectedCities}
        selectedFarmTypes={selectedFarmTypes}
        searchQuery={searchQuery}
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
        userData = {userData}
        idToken={idToken}
      />
    </Box>
  );
};

export default Home;